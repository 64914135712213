<p-toast position="top-center"/>
<div class="flex flex-column w-full h-full overflow-hidden">
    <p-menubar>
        <ng-template pTemplate="start">
            <div class="flex align-items-center gap-3">
                <p-image 
                    src="../../../../assets/img/poc.png" 
                    alt="Image" 
                    width="24" />
                <span>Convertissez vos dxf en images vectorielles</span>
                <div class="flex gap-1">
                    <p-image 
                        src="../../../../assets/img/dxf.png" 
                        alt="Image" 
                        width="24" />
                    <span><--></span>
                    <p-image 
                        src="../../../../assets/img/svg.png" 
                        alt="Image" 
                        width="24" />
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="item" let-item let-root="root">
        </ng-template>
        <ng-template pTemplate="end">
        </ng-template>
    </p-menubar>
    <div class="overflow-hidden">
        <p-splitter 
            [panelSizes]="[60, 40]" 
            [minSizes]="[50, 10]"
            [style]="{ height: '100%' }">
            <ng-template pTemplate>
                <div class="flex w-full h-full pt-6 justify-content-center overflow-auto gap-5">
                    <div class="p-5">
                        <div [style]="{textAlign: 'justify', hyphens: 'auto', maxWidth: '500px'}">
                            <p>
                                <i class="pi pi-lightbulb" style="color: orange"></i>
                                Besoin d'imprimer un DXF en grand format sans perte de qualité ? Convertissez-le en SVG !
                                <br>
                                <i class="pi pi-lightbulb" style="color: orange"></i>
                                Envie d'utiliser une image SVG comme bloc dans votre plan Autocad ? Convertissez-la en DXF !
                            </p>
                            <p class="flex gap-2 align-items-center">Un commentaire, une requête, contactez-moi sur
                                <a [classList]="'flex gap-2'" href="https://www.linkedin.com/in/pierreplaissy/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer" class="linkedin">
                                LinkedIn
                                    <img src="assets/img/linkedin.svg" alt="LinkedIn" width="24" height="24" [style]="{marginTop: '-4px'}">
                                </a>
                            </p>
                        </div>
                        @if (svgString) {
                            <p-card>
                                <ng-template pTemplate="header">
                                    @if (fileType === 'dxf') {
                                        <div class="p-3" [innerHtml]="safeHtml()"></div>
                                    } @else {
                                        <div class="flex justify-content-center">
                                            <img role="presentation" [alt]="'file.name'" [src]="'../../../../assets/img/' + targetFileType() + '.png'" class="w-5rem" />
                                        </div>
                                    }
                                </ng-template>
                                <p>
                                    {{resultMessage}}
                                </p>
                                <ng-template pTemplate="footer">
                                    <div class="flex gap-3 mt-1">
                                        <p-button label="Un autre ?" (onClick)="reset()" class="w-full" styleClass="w-full" />
                                    </div>
                                </ng-template>
                            </p-card>                
                        } @else {
                            <p-fileUpload name="myfile[]" 
                                [files]="files"
                                [auto]="true"
                                url="https://dev.ecsy.fr/api/dxftosvg/convert" 
                                [multiple]="false" accept=".dxf, .zip, .svg" 
                                maxFileSize="1048576" 
                                [invalidFileSizeMessageSummary]="'{0} : Taille incorrecte, '"
                                invalidFileSizeMessageDetail="maximum autorisé : 1Mo."
                                [invalidFileTypeMessageSummary]="'{0} : Type incorrect, '"
                                invalidFileTypeMessageDetail="types autorisés : dxf, svg, zip."
                                customUpload="true"
                                (uploadHandler)="onFileUpload($event)"
                                (onSelect)="onSelectedFiles($event)">
                                <ng-template pTemplate="header" 
                                    let-files 
                                    let-chooseCallback="chooseCallback" 
                                    let-clearCallback="clearCallback" 
                                    let-uploadCallback="uploadCallback">
                                    <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                                        <div class="flex gap-2">
                                            <p-button (onClick)="choose($event, chooseCallback)" pTooltip="Sélectionner un fichier" tooltipPosition="bottom" icon="pi pi-images" [rounded]="true" [outlined]="true" />
                                        </div>
                                        <p-progressBar [value]="totalSizePercent" [showValue]="false" styleClass="md:w-20rem h-1rem w-full md:ml-auto" [ngClass]="{ 'exceeded-progress-bar': totalSizePercent > 100 }"/>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content" 
                                    let-files 
                                    let-uploadedFiles="uploadedFiles" 
                                    let-removeFileCallback="removeFileCallback" 
                                    let-removeUploadedFileCallback="removeUploadedFileCallback">
                                    @if (waitingForResponse) {
                                        <div class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                            <div>
                                                <img role="presentation" [alt]="'svg file'" [src]="'../../../../assets/img/svg.png'" />
                                            </div>
                                            <span class="font-semibold">Conversion et récupération du résultat...</span>
                                            </div>
                                    }
                                    @if (files?.length > 0 && !waitingForResponse) {
                                        <div>
                                            <h5>{{'Taille du fichier : ' + readableFileSize()}}</h5>
                                            <div class="flex flex-wrap p-0 sm:p-5 gap-5">
                                                @for (file of files; track $index) {
                                                    <div class="card m-0 px-6 flex flex-column border-1 surface-border align-items-center gap-3">
                                                        <div>
                                                            <img role="presentation" [alt]="file.name" [src]="'../../../../assets/img/' + fileType + '.png'" width="50px" />
                                                        </div>
                                                        <span class="font-semibold">{{ file.name }}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                </ng-template>
                                <ng-template pTemplate="file"> </ng-template>
                                <ng-template pTemplate="empty">
                                    <div class="flex align-items-center justify-content-center flex-column">
                                        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
                                        <p class="mt-4 mb-0" [style.text-align]="'center'">
                                            Glissez votre fichier ici pour commencer la conversion <br>
                                            ou utilisez le bouton de sélection.
                                        </p>
                                    </div>
                                </ng-template>
                            </p-fileUpload>
                        }
                        <small>Code &#169; Pierre Plaissy</small>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate>
                <div class="col overflow-auto">
                    <markdown
                        [src]="'../../../../assets/doc/home.md'">
                  </markdown>
                </div>
            </ng-template>
        </p-splitter>    
    </div>
</div>
