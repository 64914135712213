import { Component } from '@angular/core';
import { MenubarModule } from 'primeng/menubar';
import { ImageModule } from 'primeng/image';
import { SplitterModule } from 'primeng/splitter';
import { FileSelectEvent, FileUploadHandlerEvent, FileUploadModule } from 'primeng/fileupload';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { MarkdownModule } from 'ngx-markdown';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-convert',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MenubarModule, 
    ImageModule, 
    SplitterModule, 
    FileUploadModule, 
    ProgressBarModule,
    ToastModule,
    ButtonModule,
    CardModule,
    TooltipModule,
    AccordionModule,
    InputTextareaModule,
    InputTextModule,
    MarkdownModule
  ],
  providers: [MessageService],
  templateUrl: './convert.component.html',
  styleUrl: './convert.component.scss'
})
export class ConvertComponent {
  //url = "http://localhost:5010/dxftosvg/convert";
  url = "https://api.planb.pplaissy.fr/dxftosvg/convert";
  
  files: File[] = [];
  fileType: string = "";
  fileName: string | undefined;

  totalSize : number = 0;

  totalSizePercent : number = 0;
  waitingForResponse: boolean = false;
  svgString: string | undefined;
  resultMessage: string = "";

constructor(private http: HttpClient, private sanitizer: DomSanitizer, private messageService: MessageService) {}

readableFileSize(): string {
  return Math.round(this.totalSize / 1024 / 1024 * 100) / 100 + 'Mo';
}

  async onFileUpload(e: FileUploadHandlerEvent): Promise<void> {
    let ip = await this.getIp();

    const formData: FormData = new FormData();
    const file = e.files[0];
    this.fileName = file.name;
    this.fileType = this.fileName.substring(this.fileName.lastIndexOf(".") + 1);

    const headers = new HttpHeaders({"pb-caller": "dxf2svg"});

    formData.append("Ip", ip);
    formData.append('file', file, file.name);
    this.http.post(this.url, formData, 
      { headers: headers,
        responseType: 'arraybuffer',
        reportProgress: true,
        observe: 'events'
       })
      .subscribe({
        next: (e: HttpEvent<any>) => {
          switch (e.type) {
            case HttpEventType.Sent:
              break;
            case HttpEventType.Response:
              try {
                const decodedText = new TextDecoder().decode(e.body);
                const errorPayload = JSON.parse(decodedText);
                const message = errorPayload.message;
                this.messageService.add({ severity: 'error', summary: 'Impossible de convertir ce fichier', detail: message });
              } catch (error) {
                this.resolveAndDownloadBlob(e.body, this.targetFileName());
              }
              this.waitingForResponse = false;
              this.files = [];
              this.totalSizePercent = 0;
              break;
            case HttpEventType.UploadProgress:
              this.totalSizePercent= e.loaded * 100 / this.totalSize;
              if (this.totalSizePercent >= 100) {
                this.waitingForResponse = true;
              }
              break;
            default:
              break;
          }
        },
        error: (e: any) => {
          this.messageService.add({ severity: 'error', summary: 'Impossible de convertir ce fichier', detail: "Votre fichier présente un cas non encore prévu. Je vais regarder ça." });
          this.waitingForResponse = false;
          this.files = [];
          this.totalSizePercent = 0;
    },
        complete: () => {

        }
    });
  }

  private targetFileName(): string {
    if (this.fileType === "dxf") {
      return "svgresult.svg";
    }
    return "dxfresult.dxf";
  }

  public targetFileType(): string {
    if (this.fileType === "dxf") {
      return "svg";
    }
    return "dxf";
  }

  private resolveAndDownloadBlob(response: any, fileName: string): boolean {
    try {
      this.resultMessage = "Votre fichier a été converti en " + this.targetFileType().toUpperCase() + " et déposé dans le dossier 'Téléchargements'";
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
      const reader = new FileReader();

      if (this.fileType === "dxf") {
        // après une conversion vers svg on peut afficher le résultat à l'écran
          reader.addEventListener("loadend", (e) => {
            this.svgString = e.target!.result as string;
          });
      } else {
        this.svgString = '<img role="presentation" [alt]="file.name" [src]="\'../../../../assets/img/\' + fileType + \'.png\'" width="50" />';
      }
      reader.readAsText(blob);
      this.messageService.add({ severity: 'success', summary: 'Fichier converti', detail: 'Et voilà le travail !' });

      return true;
    } catch (error) {
        return false;
    }
}

reset(): void {
  this.fileName = undefined;
  this.svgString = undefined;
}

safeHtml(): SafeHtml | undefined {
  if (this.svgString) {
    return this.sanitizer.bypassSecurityTrustHtml(this.svgString);
  }
  return undefined;
}

  onSelectedFiles(e: FileSelectEvent) {
    if (e.currentFiles.length == 1) {
      const file = e.currentFiles[0];
      this.fileType = file.name.substring(file.name.indexOf('.') + 1);
      this.totalSize = file.size;
    }
  }

  choose(e: MouseEvent, callback: any) {
    callback();
  }

  uploadEvent(callback: any) {
    callback();
  }

  async getIp(): Promise<string> {
    // on passe ?format=json puisque le get est par défaut en responseType json
    const tmp: any = await lastValueFrom(this.http.get('https://api.ipify.org?format=json'));

    return tmp.ip;
  }
}
